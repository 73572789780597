<template>
  <div>
    <h4>
      Serienummer gegevens
    </h4>
    <table class="table">
      <tr>
        <td><strong>Serienummer:</strong></td>
        <td>{{ serial.SerialID }}</td>
      </tr>
      <tr>
        <td><strong>Huurperiode:</strong></td>
        <td>
          {{ rentalPeriod }}
        </td>
      </tr>
      <tr>
        <td><strong>Serienummer leverancier:</strong></td>
        <td>{{ serial.ManufacturerSerial || 'Onbekend' }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    serial: {
      required: true,
      type: Object
    }
  },
  computed: {
    rentalPeriod() {
      const startDate = dayjs(this.serial.DateTimeBusinessStart).format(
        'DD-MM-YYYY'
      )
      const endDate = dayjs(this.serial.DateTimeBusinessEnd).format(
        'DD-MM-YYYY'
      )
      return `${startDate} T/M ${endDate}`
    }
  }
}
</script>

<style></style>
