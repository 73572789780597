<template>
  <div>
    <h3>Serienummers in huur</h3>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eleifend
      metus enim, eu tristique dui sollicitudin sit amet. Sed interdum, felis
      quis tincidunt condimentum, nisl elit tempor justo, dictum pulvinar velit
      metus eu eros.
    </p>

    <b-table
      v-if="serials.length > 0"
      :items="serials"
      :fields="fields"
      responsive
    >
      <template v-slot:cell(ReportID)="data">
        <serials-modal-info
          :serialid="`rented-${data.item.SerialID}`"
          :serial="data.item"
        />
      </template>
    </b-table>
    <p v-else>
      Je hebt nog geen serienummers in huur om te bekijken. Wanneer je een
      nieuwe reservering aanmaakt zal deze hier worden getoond.
    </p>
  </div>
</template>

<script>
import SerialsModalInfo from './SerialsModalInfo.vue'
import { BTable } from 'bootstrap-vue'
import { getRentedSerials } from '@/services/CustomerSerialService'

export default {
  name: 'RentedSerials',
  components: {
    BTable,
    SerialsModalInfo
  },
  data() {
    return {
      serials: [],
      fields: [
        {
          key: 'SerialID',
          label: 'Serienummer'
        },
        {
          key: 'Description',
          label: 'Omschrijving'
        },

        {
          key: 'ReportID',
          label: 'Acties'
        }
      ]
    }
  },
  created: async function() {
    this.serials = await getRentedSerials({
      customerID: this.$store.state.customerID
    })
  }
}
</script>

<style scss></style>
