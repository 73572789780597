<template>
  <div>
    <b-button
      :v-b-modal="serialid"
      class="mb-3"
      size="sm"
      variant="primary"
      @click="showModal"
      ><font-awesome-icon icon="info-circle"
    /></b-button>

    <b-modal
      :id="`modal-${serialid}`"
      size="xl"
      :title="
        `Serienummer informatie ${serial.Description} - ${serial.SerialID}`
      "
    >
      <b-tabs content-class="mt-3">
        <b-tab title="Artikel" active
          ><item-info :itemid="serial.ItemID"
        /></b-tab>
        <b-tab title="Serienummer"><serial-info :serial="serial"/></b-tab>
        <b-tab title="Services"
          ><serial-service-info :serialid="serial.SerialID"
        /></b-tab>
        <b-tab title="Bestanden">
          <serials-modal-serial-attachments :serialid="serial.SerialID" />
        </b-tab>
      </b-tabs>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="hideModal()"
          >
            Sluiten
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BTabs, BTab } from 'bootstrap-vue'

import ItemInfo from './SerialsModalItemInfo'
import SerialInfo from './SerialsModalSerialInfo'
import SerialServiceInfo from '@/components/serial/SerialServiceInfo.vue'
import SerialsModalSerialAttachments from './SerialsModalSerialAttachments.vue'

export default {
  components: {
    BModal,
    BButton,
    BTabs,
    BTab,
    ItemInfo,
    SerialInfo,
    SerialServiceInfo,
    SerialsModalSerialAttachments
  },
  props: {
    serialid: {
      required: true,
      type: String,
      default: function() {
        return ''
      }
    },
    serial: {
      required: true,
      type: Object,
      default: function() {
        return {}
      }
    }
  },

  methods: {
    showModal() {
      this.$bvModal.show(`modal-${this.serialid}`)
    },
    hideModal() {
      this.$bvModal.hide(`modal-${this.serialid}`)
    }
  }
}
</script>
